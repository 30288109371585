import { ItemRecord } from "./Signage/ItemDataSource";

export type ItemAttributes = {
    country?: string
    depth?: string
    description?: string
    height?: string
    vendor?: string
    width?: string
};

export type ItemProperties = {
    attribute?: ItemAttributes
};


export function properties(i: ItemRecord): ItemProperties {
    let output: any = {};

    for (const p of i.properties) {
        const bits = p.key.split('.');

        let r = output;
        for (const bit of bits.slice(0, bits.length - 1)) {
            if (!(bit in r)) {
                r[bit] = {};
            }
            r = r[bit];
        }

        r[bits[bits.length - 1]] = p.value;
    }

    return output;
}

export function attributes(i: ItemRecord): ItemAttributes {
    return properties(i).attribute || {};
}

export function size(attr: ItemAttributes) {
    return attr.width && attr.height && attr.depth
        ? `${attr.width} x ${attr.depth} x ${attr.height}`
        : undefined;
}
