export type CM = {
    cm: number
};

export type Unit = CM;

export type FontRecord = {
    id: number;
    font: FontInfo;
}

export type FontInfo = {
    folder: string,
    filename: string
};

export type TextRect    = { type: 'TextRect',    properties: TextRectProperties,    text:    string };
export type TextLine    = { type: 'TextLine',    properties: TextLineProperties,    runs:    TextLineRun[] };
export type TextLineRun = { type: 'TextLineRun', properties: TextLineRunProperties, text:    string }
export type Barcode = { type: 'Barcode', properties: BarcodeProperties, barcode: string }
export type TableElement = { type: 'Table', properties: TableProperties, rows: TableRow[] }


export type ColumnLayout = { left: Unit, width: Unit };
export type TableLayout = { rowHeight: Unit, columns: ColumnLayout[] }
export type TableColumn = { text: string, span?: number } | string;
export type TableRow = { columns: TableColumn[], span?: number } | TableColumn[];


export type Element = TextRect | TextLine | Barcode | TableElement;

export type WithContent = {
    content: Element[]
}

export type Page = WithContent;

export function cm(n: number) {
    return { cm: n };
}


export type TextLocation = {
    left: Unit | string,
	baseline: Unit | string,
    height: Unit | string,
};

export type TextRectProperties = TextLocation & {
    width?: Unit | string,
    verticalShiftPerExtraLine?: Unit | string,
    font: FontRecord,
    fontsize: number,
    align: 'center' | 'left' | 'right'
};	

export type TextLineProperties = TextLocation & {
	left: string,
	baseline: string,
	height: string,

	align: 'center' | 'left' | 'right',
	autoscalefont: boolean
};

export type TextLineRunProperties = {
    font: FontRecord,
	fontsize: number,
	strikeout?: boolean
};

export type BarcodeProperties = {
	left: string,
	bottom: string,
	width: string,
	type: 'EAN13',
	align: 'center' | 'left' | 'right',
	scale: number,
	trailing: boolean
}

export type TableProperties = {
    topLine: Unit,
    font: FontRecord,
    layout: TableLayout
};

export function page(...content: (Element | null)[]): Page {
    return {
        content: content.filter(x => x !== null).map(x => x!)
    };
}

export function textRect(properties: TextRectProperties, content: string): TextRect {
	return {
		type: 'TextRect',
		properties,
		text: content
	};
}

export function textLineRun(properties: TextLineRunProperties, content: string): TextLineRun {
	return {
		type: 'TextLineRun',
		properties,
		text: content
	};
}

export function textLine(properties: TextLineProperties, runs: TextLineRun[]): TextLine {
	return {
		type: 'TextLine',
		properties,
		runs
	};
}

export function barcode(properties: BarcodeProperties, barcode: string): Barcode {
	return {
		type: 'Barcode',
		properties,
		barcode
	};
}

export function table(properties: TableProperties, rows: TableRow[]): TableElement {
    return {
        type: 'Table',
        properties,
        rows
    }
}

export const circularBook = {
    folder: 'Circular',
    filename: 'CircularStd-Book.ttf'
};

export const circularMedium = {
    folder: 'Circular',
    filename: 'CircularStd-Medium.ttf'
};

export const circularBlack = {
    folder: 'Circular',
    filename: 'CircularStd-Black.ttf'
};
