import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
//import { CentaraLogo, CentaraBackgroundColor } from 'centara-react-assets';

const CentaraBackgroundColor = () => 'rgb(28, 57, 92)';

class CentaraLogo extends Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return <svg x="0px" y="0px" width="170px" height="24px" viewBox="0 0 170 24" style={{ display: 'block' }}>
            <path fill="#D0D2D3" d="M21.244,8.056v7.691h-7.961c-0.798,0-1.444-0.63-1.444-1.403V9.463c0-0.778,0.646-1.408,1.444-1.408
			L21.244,8.056L21.244,8.056z"></path><g><path fill="#ffffff" d="M142.559,23.1l0.029,0.028h-7.581l-10.716-11.433h8.051c1.791,0,2.726-0.607,2.726-1.982V6.551
				c0-1.371-1.021-1.945-2.812-1.945h-7.965v7.089v3.64V23.1h-1.021c-3.268-0.449-5.182-1.787-5.581-4.468V5.615
				c0.465-3.135,2.997-4.431,7.348-4.621h8.839c6.137,0,7.709,2.681,7.709,5.654v2.973c0,2.362-1.021,5.459-6.346,5.459h-0.85
				l6.963,6.835l-0.56,1.185H142.559z"></path><path fill="#ffffff" d="M73.539,0.994c-0.513,0.019-0.997,0.06-1.458,0.116c-0.323,0.037-0.637,0.083-0.936,0.139
				c-2.864,0.528-4.55,1.843-4.94,4.311v8.627l0.166,1.755h-0.337l-0.94-1.598l-9.367-13.35h-8.521v18.337l0,0l0,0H30.084V15.14
				v-1.329v-0.005h4.997c2.356-0.07,4.204-0.445,5.51-1.232v-2.551H30.084v-5.26h5.472V4.754h0.399c4.626,0,7.49-1.014,8.36-3.76
				h-13.57C26.692,1.17,24.222,2.309,23.524,5v14.248c0.589,2.274,2.446,3.44,5.453,3.853h18.23l0,0h0.214
				c0.917-0.125,1.729-0.324,2.427-0.597l0,0c2.123-0.838,3.235-2.413,3.235-5.006v-3.065V8.435l-0.166-1.787h0.337l0.94,1.533
				l10.564,14.947h7.324V4.606h7.923V19.1c0.542,2.371,2.413,3.579,5.486,4.001h1.073V6.083V4.754V4.606h3.121
				c3.035-0.366,4.954-1.454,5.638-3.612C95.323,0.994,73.539,0.994,73.539,0.994z"></path><path fill="#ffffff" d="M21.567,19.743v2.621c-1.662,0.796-4.389,1.181-6.388,1.181H8.405c-5.624,0-8.649-1.5-8.649-5.779V6.329
				c0-4.279,3.026-5.784,8.649-5.784h6.987c2,0,4.384,0.194,6.175,0.991v2.811H9.085c-1.829,0-2.769,0.514-2.769,1.885v11.627
				c0,1.375,0.94,1.885,2.769,1.885h12.482V19.743z"></path><path fill="#ffffff" d="M116.582,20.961L116.582,20.961l-3.729-7.803l-5.899-12.354H99.16l-2.498,5.293L88.639,23.1h0.237h5.595
				l2.912-6.844l0,0l0,0l0,0c0.94,0.556,2.161,0.898,3.638,1.07h7.381l2.513,5.774h5.667h1.021L116.582,20.961z M98.613,13.71
				L98.613,13.71h-0.147l0,0l3.672-8.627l0.427-1.31h0.128l0.385,1.31l3.752,8.627H98.613z"></path><path fill="#ffffff" d="M168.735,20.961L168.735,20.961l-3.729-7.803l-5.899-12.354h-7.794l-2.498,5.293L140.792,23.1h0.237h5.595
				l2.912-6.844l0,0l0,0l0,0c0.94,0.556,2.161,0.898,3.638,1.07h7.381l2.513,5.774h5.667h1.021L168.735,20.961z M150.766,13.71
				L150.766,13.71h-0.147l0,0l3.672-8.627l0.427-1.31h0.128l0.385,1.31l3.752,8.627H150.766z">
            </path></g></svg>;
    }
}

function LogoTitle({ title }: { title: string }) {
    return (
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <div style={{ marginRight: '0.3em' }}>
                <div style={{ marginBottom: '-1px' }}>
                    <CentaraLogo />
                </div>
            </div>
            <div style={{ color: 'rgba(255,255,255,0.9)', fontSize: '1.4em' }}>
                {title}
            </div>
        </div>
    );
}


export class NavMenu extends Component<any, any> {
    //static displayName = NavMenu.name;

    constructor(props: any) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm   border-bottom box-shadow mb-3" dark style={{ background: CentaraBackgroundColor() }}>
                    <Container>
                        <NavbarBrand tag={Link} to="/" style={{ padding: 0 }}>
                            <LogoTitle title="Signage" />
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                                </NavItem>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
