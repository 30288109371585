import React, { Component } from 'react';
import { UserManager, UserManagerSettings, User } from 'oidc-client';
import { Redirect, Switch, Route } from 'react-router';

type P = { children: (user: User) => React.ReactNode };
type S = {
    user: User | null
};


const config = () => ({
    authority: "https://users.centara.online",
    client_id: "centara-item-signage-js",
    redirect_uri: window.location.origin + '/login-callback',
    response_type: "id_token token",
    scope: "openid profile centara_profile discount-offer-api-1.read_config",
    post_logout_redirect_uri: window.location.href,
});


export class LoginCallback extends Component<{}, { done: boolean }> {
    constructor(props: {}) {
        super(props);
        this.state = { done: false };

        const mgr = new UserManager(config());
        mgr.signinRedirectCallback()
            .then(_ => window.location.href = window.location.origin);
    }

    render() {
        const loc = window.location;

        return (
            this.state.done
                ? <div>Please wait</div>
                : <Redirect to="/" />
        );
    }
}

class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
    constructor(props: {}) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, info: any) {
        // You can also log the error to an error reporting service
        //logErrorToMyService(error, info);
        console.log('catch', { error, info });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}

export class Login extends Component<P, S> {
    mgr: UserManager;

    constructor(props: P) {
        super(props);
        this.state = {
            user: null
        }
        const cfg = config();
        console.log('oidc config', cfg);
        this.mgr = new UserManager(cfg);
    }

    async componentDidMount() {
        const user = await this.mgr.getUser();
        this.setState({ user });
    }

    login() {
        return this.mgr.signinRedirect();
    }

    logout() {
        return this.mgr.signoutRedirect();
    }

    render() {
        const { user } = this.state;
        return (
            <Switch>
                <Route exact path='/login-callback' component={LoginCallback} />
                <Route path='/'>
                    {user === null
                        ? <button onClick={_ => this.login()}>Login</button>
                        : <div>
                            <button onClick={_ => this.logout()}>Logout</button>
                            <ErrorBoundary>
                                {this.props.children(user)}
                            </ErrorBoundary>
                            </div>}
                </Route>
            </Switch>
        );
    }
}
