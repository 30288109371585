import React, { Component } from 'react';
import { User } from 'oidc-client';
import { Link } from 'react-router-dom';

type P = {
    user: User
};

type S = {
    sites: SiteInfo[] | 'loading' | 'error'
};

type SiteInfo = {
    siteName: string
};


export class Home extends Component<P, S> {
    constructor(props: P) {
        super(props);
        this.state = {
            sites: 'loading'
        }
    }


    async componentDidMount() {
        const org = this.props.user.profile.organization;
        if (!org) {
            throw Error("Organization not found on user claim");
        }

        console.log('requesting org info');
        const rq = await fetch(`https://devices.centara.online/api/v1/sites`, {
            headers: {
                Authorization: 'Bearer ' + this.props.user.access_token
            }
        });

        const sites: SiteInfo[] = await rq.json();
        console.log('got site list', sites);
        await this.setState({ sites });
    }

    render() {
        const { sites } = this.state;
        return (
            <div>
                <h1>Welcome {this.props.user.profile.name}</h1>

                {sites === 'loading' ? <div>Loading...</div> :
                    sites === 'error' ? <div>Error loading sites</div> :
                        sites.sort(function (siteA, siteB)
                        {
                            var a = siteA.siteName.toLowerCase();
                            var b = siteB.siteName.toLowerCase();
                            return ((a < b) ? -1 : ((a > b) ? 1 : 0));
                        }).map(site => <li key={site.siteName}>
                            <Link to={`signage/t/${encodeURIComponent(site.siteName)}`}>{site.siteName}</Link>
                        </li>)
                }
            </div>
        );
    }
}
