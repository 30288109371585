import React, { Component, Fragment, useState, useEffect } from 'react';
import { DiscountData, canonical, deduceRewardType, rewardText, ItemRecord, loadItems, ServerRewardObject, ItemSetRecord } from './ItemDataSource';
import * as CL from './Layouts/CommonLayout';
import * as Layout1 from './Layouts/Layout1';
import * as Layout2 from './Layouts/Layout2';
import * as Layout3 from './Layouts/Layout3';
import * as Layout4 from './Layouts/Layout4';
import { isIndividualItemReward, offerPrice } from './DiscountAnalysis';
import { size, attributes } from '../Items';

type P = {
    site: string,
    store: string,
    accessToken: string,
    discount: DiscountData
};

type SelectorData = {
    reward: ServerRewardObject,
    items: ItemRecord[]
};
type SelectorState = SelectorData | 'loading' | 'error' | 'pending';

function flatten<T>(xs: T[][]) {
    return xs.reduce((a, b) => a.concat(b), []);
}

function selectors(r: DiscountData) {
    const canon = canonical(r);
    if (!canon) {
        return [];
    }

    return flatten(canon.ItemSets.filter(isIndividualItemReward).map(iset => iset.Rules.map(rule => ({ reward: iset.Rewards.filter(rw => rw.Id === rule.RewardId)[0], selector: rule.Selector }))));
}


function maybe<T, R>(f: (x: T) => R, x: T | null) {
    return x === null
        ? null
        : f(x);
}

function formatAmount(n: number) {
    return Math.round(n).toLocaleString().replace(",", ".");
}

function signageRecord(item: ItemRecord, reward: ServerRewardObject): CL.ItemRecord {
    const attr = attributes(item);
    return {
        itemNumber: item.id,
        itemName: item.description,
        description: item.description,
        discount: rewardText(reward),
        discountPrice: maybe(formatAmount, offerPrice(reward, item.price)),
        originalPrice: formatAmount(item.price),
        size: size(attr) || '',
        countryOfOrigin: attr.country || '',
        detailedDescription: attr.description || '',
        barcode: item.barcodes.filter(bc => bc.length === 13)[0] || ''
    };
}
function range(start: number, count: number) {
    const r: number[] = [];
    for (let i = start; i < start + count; ++i) {
        r.push(i);
    }
    return r;
}


export function DiscountDetails({ site, store, accessToken, discount }: P) {
    const [selectorData, setSelectorData] = useState<{ [selector: string]: SelectorState }>({});
    const [loading, setLoading] = useState<string|null>(null);
    const [visibleSelectors, setVisibleSelectors] = useState<string[]>([]);

    useEffect(() => {
        let stop = false;
        const controller = new AbortController();
        const { signal } = controller;

        async function f() {
            setLoading(store);
            const sels = selectors(discount);

            const pendingSelectors = sels/*.filter(s =>
                !(s.selector in selectorData)
                || selectorData[s.selector] === 'loading'
                || selectorData[s.selector] === 'error'
                || selectorData[s.selector] === 'pending'
            );*/

            console.log('preparing item load', { discount, sels, pendingSelectors });

            if (pendingSelectors.length === 0) {
                return;
            }
            function setPending(sels: { [sel: string]: SelectorState }, sel: string): { [sel: string]: SelectorState } {
                return { ...sels, [sel]: 'pending' };
            }

            const stateWithPending = pendingSelectors.reduce(
                (st, s) => setPending(st, s.selector),
                selectorData
            );

            let updatedSelectorData = stateWithPending;
            setSelectorData(stateWithPending);


            for (const s of pendingSelectors) {
                console.log('loading items', { s });

                updatedSelectorData = { ...updatedSelectorData, [s.selector]: 'loading' };
                setSelectorData(updatedSelectorData);

                const items = await loadItems(site, store, accessToken, s.selector, signal);
                if (stop) {
                    return;
                }
                console.log('got', items.length);

                updatedSelectorData = { ...updatedSelectorData, [s.selector]: { reward: s.reward, items } };
                setSelectorData(updatedSelectorData);
            }

            setLoading(null);
        }

        f();

        return () => {
            stop = true;
            controller.abort();
        };
    }, [site, store, accessToken, discount]);


    const canon = canonical(discount);

    if (!canon) {
        return <div>No discount data</div>;
    }

    return (
        <div>
            <h2>{discount.Id}: {canon.Name}</h2>
            <h3>PDFs</h3>
            {loading ? <p>Loading items for store {loading}</p> : null}
            {Object.keys(selectorData).map((sel, selidx) => {
                const data = selectorData[sel];
                const itemsetCode = String.fromCharCode(65 + selidx);

                if (data === 'loading') {
                    return <div key={sel}>Loading...</div>;
                }

                if (data === 'error') {
                    return <div key={sel}>Error...</div>;
                }

                if (data === 'pending') {
                    return <div key={sel}>Queued...</div>;
                }

                const itemsPerPdf = 3000;
                const pdfCount = Math.ceil(data.items.length / itemsPerPdf);

                return (
                    <div key={sel}>
                        {range(0, pdfCount).map(pdfNumber =>
                            <div key={pdfNumber} style={{ marginBottom: '0.2cm', padding: '0.1cm' }}>
                                <span style={{ marginBottom: '0.2cm' }}><b>{discount.Id}-{itemsetCode}</b> - {data.items.length} items</span>
                                <form id={`pdfgen-${selidx}`} method="POST" action="/api/pdf/generate" target="_blank">
                                    <input type="submit" value={`Útsala PDF (${pdfNumber + 1} of ${pdfCount})`} />
                                    <input type="hidden"
                                        name="fileName"
                                        value={`${discount.Id}-${itemsetCode}-${pdfNumber + 1}of${pdfCount}.pdf`}
                                    />
                                    <input type="hidden"
                                        name="layout"
                                        value={JSON.stringify(Layout1.layout(data.items.slice(pdfNumber * itemsPerPdf, (pdfNumber + 1) * itemsPerPdf).map(item => signageRecord(item, data.reward))))}
                                    />
                                </form>
                                <form id={`pdfgenTB-${selidx}`} method="POST" action="/api/pdf/generate" target="_blank">
                                    <input type="submit" value={`Tilboð PDF (${pdfNumber + 1} of ${pdfCount})`} />
                                    <input type="hidden"
                                        name="fileName"
                                        value={`${discount.Id}-${itemsetCode}-${pdfNumber + 1}of${pdfCount}.pdf`}
                                    />
                                    <input type="hidden"
                                        name="layout"
                                        value={JSON.stringify(Layout4.layout(data.items.slice(pdfNumber * itemsPerPdf, (pdfNumber + 1) * itemsPerPdf).map(item => signageRecord(item, data.reward))))}
                                    />
                                </form>
                                <form id={`pdfgenBF-${selidx}`} method="POST" action="/api/pdf/generate" target="_blank">
                                    <input type="submit" value={`Black Friday PDF (${pdfNumber + 1} of ${pdfCount})`} />
                                    <input type="hidden"
                                        name="fileName"
                                        value={`${discount.Id}-${itemsetCode}-${pdfNumber + 1}of${pdfCount}.pdf`}
                                    />
                                    <input type="hidden"
                                        name="layout"
                                        value={JSON.stringify(Layout2.layout(data.items.slice(pdfNumber * itemsPerPdf, (pdfNumber + 1) * itemsPerPdf).map(item => signageRecord(item, data.reward))))}
                                    />
                                </form>
                                <form id={`pdfgenTF-${selidx}`} method="POST" action="/api/pdf/generate" target="_blank">
                                    <input type="submit" value={`TAXFREE PDF (${pdfNumber + 1} of ${pdfCount})`} />
                                    <input type="hidden"
                                        name="fileName"
                                        value={`${discount.Id}-${itemsetCode}-${pdfNumber + 1}of${pdfCount}.pdf`}
                                    />
                                    <input type="hidden"
                                        name="layout"
                                        value={JSON.stringify(Layout3.layout(data.items.slice(pdfNumber * itemsPerPdf, (pdfNumber + 1) * itemsPerPdf).map(item => signageRecord(item, data.reward))))}
                                    />
                                </form>
                            </div>
                        )}
                    </div>
                );
            })}

            <table>
                {Object.keys(selectorData).map((sel, selidx) => {
                    const data = selectorData[sel];
                    const itemsetCode = String.fromCharCode(65 + selidx);

                    if (data === 'loading') {
                        return null;
                    }

                    if (data === 'error') {
                        return null;
                    }

                    if (data === 'pending') {
                        return null;
                    }

                    if (!(sel in visibleSelectors)) {
                        return null;
                    }

                    return (
                        <Fragment key={sel}>
                            <thead>
                                <tr>
                                    <th colSpan={8}>
                                        <h4>
                                            {`${discount.Id}-${itemsetCode}`}
                                        </h4>
                                    </th>
                                </tr>
                                <tr>
                                    <th>Item</th>
                                    <th>Description</th>
                                    <th>Price</th>
                                    <th>Offer price</th>
                                    <th>Discount</th>
                                    <th>Country</th>
                                    <th>Description</th>
                                    <th>Size</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.items.map((item, idx) => {
                                    const reward = data.reward;
                                    const attr = attributes(item);
                                    return (
                                        <tr key={idx}>
                                            <td>{item.id}</td>
                                            <td>{item.description}</td>
                                            <td>{item.price}</td>
                                            <td>{offerPrice(reward, item.price)}</td>
                                            <td>{rewardText(reward)}</td>
                                            <td>{attr.country}</td>
                                            <td>{attr.description}</td>
                                            <td>{size(attr)}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Fragment>
                    );
                })}
            </table>
        </div>
    );
}