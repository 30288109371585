export type Item = number;

const basePath = 'https://h03.centara.online/DiscountOfferAPI';

export interface IRuleRecord {
    Id: number;
    RewardId: number;
    Selector: string;
    Quantity: number;
}

interface ICustomField {
    Id: string;
    Description: string;
    Value: string | { Language: string; Text: string }[];
    DataType: string;
}

export interface ServerRewardObject {
    Id: number;
    Percentage: string | null;
    Discount: string | null;
    SpecialPrice: string | null;
    ResourceConsuming: string | null;
    LoyaltyPointsPercentage: string | null;
    LoyaltyPoints: string | null;
    IssueCouponId: string | null;
    LoyaltyPointsType: string | null;
    NumberOfDiscountedItemsLimit: number | null;
}

export interface ItemSetRecord {
    Id: number;
    CustomFields: ICustomField[] | null;
    ReceiptDescription: string | null;
    CouponId: string | null;
    DescriptionTranslations: string;
    Rewards: ServerRewardObject[];
    Rules: IRuleRecord[];
}


export type DiscountRecord = {
    Id: number,
    Dirty: 0 | 1,
    Name: string
    NameTranslations: string | null,
    CalculationPass: number,
    GroupTags: string[],
    Owner: {
        Id: string | null,
        Name: string
    },
    Restrictions: string,
    Validity: number,
    ItemSets: ItemSetRecord[]
};

export type DiscountData = {
    Id: number,
    Clean: DiscountRecord | null,
    Dirty: DiscountRecord | null
};

export function canonical(d: DiscountData) {
    return d.Clean || d.Dirty;
}

export function latest(d: DiscountData) {
    return d.Dirty || d.Clean;
}

export async function loadDiscounts(site: string, accessToken: string): Promise<DiscountData[]> {
    const url = `${basePath}/api/discounts/site/${site}`;
    const rsp = await fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + accessToken
        }
    });

    if (!rsp.ok) {
        const content = rsp.text();
        throw Error(`Response error: ${rsp.status} ${content}`);
    }

    const json: DiscountData[] = await rsp.json();
    return json;
}

const plregex = /^pricelevel:[0-9]*(\.[0-9]+)?$/;
const icregex = /^infocode:.+$/;
const numberregex = /^[0-9]*(\.[0-9]+)?$/;


const isValidPercentage = (s: string | null) =>
    s !== null && (
        plregex.test(s)
        || icregex.test(s)
        || (numberregex.test(s) && parseFloat(s) >= 0 && parseFloat(s) <= 100)
     );

const isValidAmount = (s:string|null) =>
    s !== null && (
        plregex.test(s)
        || icregex.test(s)
        || (numberregex.test(s) && parseFloat(s) >= 0)
    );

export const deduceRewardType = function (r: ServerRewardObject) {
    if (r.Percentage && isValidPercentage(r.Percentage)) {
        return 'percentage';
    } else if (r.Discount && isValidAmount(r.Discount)) {
        return 'amount';
    } else if (r.SpecialPrice && isValidAmount(r.SpecialPrice)) {
        return 'price';
    } else if (r.IssueCouponId) {
        return 'issue-coupon';
    } else if (r.ResourceConsuming) {
        return 'resource-consuming';
    } else if (r.LoyaltyPoints) {
        return 'loyalty-points-fixed';
    } else if (r.LoyaltyPointsPercentage) {
        return 'loyalty-points-percentage';
    } else {
        return 'unknown';
    }
};

export const deduceRewardBase = function (r: ServerRewardObject) {
    if (r.NumberOfDiscountedItemsLimit) {
        return 'leastexpensiveitems';
    } else {
        return 'allitems';
    }
};

export const rewardText = (r: ServerRewardObject) => {
    const type = deduceRewardType(r);

    var text: string;
    if (type === 'percentage') {
        text = `${(isValidPercentage(r.Percentage) ? r.Percentage : 'x')}%`;
    } else if (type === 'amount') {
        text = `-${(isValidAmount(r.Discount) ? r.Discount : 'x')}`;
    } else if (type === 'price') {
        text = `=${(isValidAmount(r.SpecialPrice) ? r.SpecialPrice : 'x')}`;
    } else if (type === 'resource-consuming') {
        text = `using loyalty (${r.LoyaltyPointsType}): ${(isValidAmount(r.ResourceConsuming) ? r.ResourceConsuming : 'x')}`;
    } else if (type === 'loyalty-points-percentage') {
        text = `loyalty (${r.LoyaltyPointsType}): ${(isValidPercentage(r.LoyaltyPointsPercentage) ? r.LoyaltyPointsPercentage : 'x')}%`;
    } else if (type === 'loyalty-points-fixed') {
        text = `loyalty (${r.LoyaltyPointsType}): ${(isValidAmount(r.LoyaltyPoints) ? r.LoyaltyPoints : 'x')}`;
    } else if (type === 'issue-coupon') {
        text = `coupon: ${r.IssueCouponId}`;
    } else {
        text = 'unknown';
    }

    if (deduceRewardBase(r) == 'leastexpensiveitems') {
        text += ' for ' + (r.NumberOfDiscountedItemsLimit || 'n');
    }

    return text;
};



export type ItemRecord = {
    id: string,
    description: string,
    departmentID: number,
    departmentDescription: string,
    price: number,
    properties: { key: string, value: string }[],
    barcodes: string[]
};

export async function loadItems(site: string, store: string, accessToken: string, selector: string, signal?: AbortSignal): Promise<ItemRecord[]> {
    const url = `${basePath}/api/discountAnalysis/site/${site}/selectorItems`;
    const rsp = await fetch(url + `?store=${encodeURIComponent(store)}`, {
        method: 'POST',
        body: JSON.stringify(selector),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        },
        signal
    });
    const json: ItemRecord[] = await rsp.json();
    return json;
}
