import { cm, barcode, FontRecord, table } from "./Declarations";


export type ItemRecord = {
    itemNumber: string,
    itemName: string,
    description?: string,
    discount: string,
    discountPrice: string | null,
    originalPrice: string,
    size?: string,
    countryOfOrigin?: string
    detailedDescription?: string
    barcode?: string
};


export function backsideTable(font: FontRecord) {
    const topLine = cm(12.75);
    const layout = {
        rowHeight: cm(0.45),
        columns: [
            { left: cm(0.7), width: cm(2.5) },
            { left: cm(3.4), width: cm(6.6) }
        ]
    };

    return [
        table({ topLine, font, layout }, [
            { span: 2, columns: ["Vöruheiti", '{itemName}'] },
            ["Vörunúmer", '{itemNumber}'],
            ["Stærð", '{size}'],
            ["Upprunaland", '{countryOfOrigin}'],
            { span: 12, columns: [{ text: '{detailedDescription}', span: 2 }] }
        ]),

        barcode({ left: "0.7cm", bottom: "0.55cm", width: "9.1cm", type: "EAN13", align: "center", scale: 1.0, trailing: false }, '{barcode}')
    ];
}


// function tsPlaygroundTest(pageLayout: (record: ItemRecord) => string) {
//     function item(
//         itemNumber: string,
//         itemName: string,
//         description: string,
//         discount: string,
//         discountPrice: string,
//         originalPrice: string,
//         size: string,
//         countryOfOrigin: string,
//         detailedDescription: string,
//         barcode: string) {
//         return { itemNumber, itemName, description, discount, discountPrice, originalPrice, size, countryOfOrigin, detailedDescription, barcode };
//     }
//     const testData = [
//         item('Vara 1', 'Sófi', 'Friday sófi með hvíld', '50%', '499.995', '999.990', '', 'Ítalía', 'Góður sófi frá Ítalíu sem er gott að lúlla í', '978020137962'),
//         item('Vara 2', 'Góð vara', 'Mjög góð vara', '20%', '500', '700', 'Large', 'Ísland', 'Frábær vara sem gerir ekki neitt', '978020137962')
//     ];
//     const pre = document.createElement('pre');
//     pre.textContent = MakeXml(testData, pageLayout);

//     document.body.appendChild(pre);
// }
