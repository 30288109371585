import { cm, textRect, page, textLineRun, textLine, circularMedium, circularBlack, circularBook } from "./Declarations";
import { ItemRecord, backsideTable } from "./CommonLayout";

const circBook = { id: 1, font: circularBook };
const circMedium = { id: 3, font: circularMedium };
const circBlack = { id: 2, font: circularBlack };

export const fonts = [circBook, circMedium, circBlack];

export const pageLayout = page(
    textRect({ left: '297.638', baseline: "12cm", height: "3cm", font: circBlack, fontsize: 62, align: "center" }, "TAXFREE"),
    textRect({ left: "297.638", baseline: cm(10.8), verticalShiftPerExtraLine: cm(0.35), height: "3cm", font: circMedium, fontsize: 18, align: "center" }, '{description}'),
    
    textLine({ left: "297.638", baseline: "8.3cm", height: "3cm", align: "center", autoscalefont: true},[
        textLineRun({ font: circMedium, fontsize: 24.0 }, 'AFSLÁTTUR '),
        textLineRun({ font: circBlack, fontsize: 24.0 }, ' {discount} ')
    ]),
    
    textRect({ left: "297.638", baseline: "6.5cm", height: "3cm", font: circMedium, fontsize: 15.7, align: "center" }, "TAXFREE-VERÐ kr."),
    textRect({ left: "297.638", baseline: "4.8cm", height: "3cm", font: circBlack, fontsize: 52.2, align: "center" }, '{discountPrice}'),
    
    textLine({ left: "297.638", baseline: "3.95cm", height: "3cm", align: "center", autoscalefont: true}, [
        textLineRun({ font: circMedium, fontsize: 14.0 }, 'Verð áður kr. '),
        textLineRun({ font: circMedium, fontsize: 14.0, strikeout: true }, ' {originalPrice} ')
    ]),
    
    textRect({ left: "297.638", baseline: "2.25cm", height: "3cm", font: circBlack, fontsize: 15, align: "center" }, "HVORKI SKILA- NÉ SKIPTIRÉTTUR"),
    
    ...backsideTable(circBook)
);

export function layout(records: ItemRecord[]) {
    return {
        records,
        pageLayout,
        fonts
    };
}
