import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Login, LoginCallback } from './components/Login';
import { Signage } from './components/Signage';

export default class App extends Component<any, any> {
    //static displayName = App.name;

    render() {
        return (
            <Layout>
                <Login>
                    {user =>
                        <>
                            <Route exact path='/' render={() => <Home user={user} />} />
                            <Route path='/signage/t/:site' render={({ match }) => <Signage site={match.params.site} path={match.url} accessToken={user.access_token} />} />
                        </>
                    }
                </Login>
            </Layout>
        );
    }
}
