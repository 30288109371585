import React, { Component } from 'react';
import { Item, DiscountRecord, loadDiscounts, DiscountData, canonical, latest } from './ItemDataSource';
import { Link, Switch, Route } from 'react-router-dom';
import { DiscountDetails } from './DiscountDetails';


type P = {
    site: string,
    path: string,
    accessToken: string
};

type S = {
    items: Item[] | null
    discounts: DiscountData[] | 'error' | null,
    store: string
};

function Index({ matchUrl, discounts }: { matchUrl: string, discounts: DiscountData[]|null }) {
    return (
        <ul>
            {discounts
                ? discounts.map(discount => {
                    const canon = canonical(discount) || { Name: 'n/a' };
                    const current = latest(discount) || { Name: 'n/a' };

                    return (
                        <li key={discount.Id}>
                            <Link to={`${matchUrl}/d/${discount.Id}`}>
                                {discount.Id}: {canon.Name === current.Name ? canon.Name : `${canon.Name} -> ${current.Name}`}
                            </Link>
                        </li>
                        );
                    }
                )
                : <span>Loading</span>}
        </ul>
    );
}


export class Signage extends Component<P, S> {
    constructor(props: P) {
        super(props);
        this.state = {
            items: null,
            discounts: null,
            store: '1'
        };
    }

    async componentDidMount() {
        try {
            const discounts = await loadDiscounts(this.props.site, this.props.accessToken);
            this.setState({ discounts });
        } catch (error) {
            console.error('Error loading discounts', error);
            this.setState({ discounts: 'error' });
        }
    }

    setStore(store: string) {
        this.setState({ store });
    }

    render() {
        const { path, site, accessToken } = this.props;
        const { discounts, store } = this.state;

        if (discounts === 'error') {
            return <h1>Error loading discounts</h1>;
        }

        return (
            <div>
                <Switch>
                    <Route path={`${path}/d/:discountId`} render={({ match }) => {

                        const dsc = (discounts || []).filter(d => d.Id == match.params.discountId);

                        return dsc.length > 0
                            ? <DiscountDetails site={site} store={store} accessToken={accessToken} discount={dsc[0]} />
                            : <div>{discounts === null ? 'Loading' : 'Unknown discount'}</div>;
                        }
                    } />
                    <Route path={`${path}`} render={_ => <Index matchUrl={path} discounts={discounts} />} />
                </Switch>

                <div>
                    <label>Store number</label>
                    <input value={this.state.store} onChange={ev => this.setStore(ev.target.value)} />
                </div>
            </div>
        );
    }
}

