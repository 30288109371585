import { ItemSetRecord, ServerRewardObject } from "./ItemDataSource";

export function offerPrice(r: ServerRewardObject, p: number) {
    if (r.Percentage) {
        return p - p * parseFloat(r.Percentage) / 100.0;
    } else if (r.SpecialPrice) {
        return parseFloat(r.SpecialPrice);
    } else if (r.Discount) {
        return p - parseFloat(r.Discount);
    } else {
        return null;
    }
}

export function isIndividualItemReward(iset: ItemSetRecord) {
    return iset.Rules.length === 1 && iset.Rewards.length === 1 && iset.Rules[0].Quantity === 1;
}
